import React, { Component } from 'react';
import WSGridTile from './Graphics/Grids/wsGridTile';

class wsGrid extends Component {
    componentDidMount() {
        // Add event listener when the component mounts         
        window.addEventListener('keydown', this.props.handleKeyPress);
    }

        
    componentWillUnmount() {
        // Remove event listener when the component unmounts   
        window.removeEventListener('keydown', this.props.handleKeyPress);
    }

    render() { 
        document.title = 'Alpha Live Graphics - Winter Series Grids';
        var ltGridDiv = {
            position: "absolute",
            width: "100%",
            height: "500px",
            marginTop: "579px",
            backgroundColor: "transparent",
            overflow: "hidden"
        }

            return ( 
                <div className="GridsApp">
                    <div id="LTGridDiv" style={ltGridDiv}>
                        {this.props.Session.Competitors.map((driver, index) => (
                            <WSGridTile  {...this.props} key={index} driver={driver} position={driver.GridPosition}/>
                        ))}
                    </div>
                </div>
            );
        }
            
}
export default wsGrid ;
