import React, { PureComponent } from 'react';
import './stylesheets/FastestLap.css';

class FastestLap extends PureComponent {
    state = { 

     }

    render() {
		
		var font = "gtws-alt"

        if(this.props.Session.blt){
            var fastestLap = this.props.msToTime(this.props.Session.blt.tm*1000)
            if (this.props.ClientStates.TimingSystem == "Apex"){
                fastestLap = fastestLap.slice(0, -1)
            }
            var competitorIndex = this.props.Session.Competitors.findIndex(object => {
                return parseInt(object.CompetitorNumber) === parseInt(this.props.Session.blt.cn);
            });
            if (competitorIndex == -1){
                var fastestLap = undefined
                var competitorIndex = undefined
                var lastName = undefined
                var firstName = undefined
                var upperCaseLastName = undefined
                var width = "725px"
                var height = "90px"
            } else {
                var lastName = this.props.Session.Competitors[competitorIndex].CompetitorName.split(" ")
                var firstName = lastName.shift()
                var upperCaseLastName = lastName.toString()
                upperCaseLastName.toUpperCase()
                
                firstName = firstName.replace(/,/g, ' ');
                upperCaseLastName = upperCaseLastName.replace(/,/g, ' ');

                if (this.props.Session.Competitors[competitorIndex].CompetitorName.length > 15 && this.props.Controls.DriverPhotos == true){
                    firstName = ""
                }
            }
            if (this.props.Controls.DriverPhotos == true){
                var height = "120px"
                var sponsorMargTop = "15%"
            } else {
                var height = "90px"
                var sponsorMargTop = "5%"
            }
            if(this.props.Controls.FastestLapShow == true){
                var width = "1400px"
            } else {
                var width = "725px"
                // var width = "1400px"
            }

            if(this.props.ClientStates.FlSponsor == true){
                // var sponsorImage = "images/sponsorLogos/"+this.props.ClientStates.TimingName+"FL.png"+`?t=${Date.now()}`
                var sponsorImage = "images/sponsorLogos/"+this.props.ClientStates.TimingName+"FL.png"
                var sponsorClass = "FlSponsorShow"
            } else {
                var sponsorImage = "images/sponsorLogos/Blank.png"
                var sponsorClass = "FlSponsorHide"
            }

            if(this.props.ClientStates.CountryFlags == true){
                var nationFlagClass = "fastestNationFlag"
                if(this.props.Session.Competitors[competitorIndex]){
                    var nationFlag = this.props.Session.Competitors[competitorIndex].nat
                }
                
            } else {
                var nationFlagClass = "fastestNoNationFlag"
                var nationFlag = "blank"
            }

            if(this.props.ClientStates.TimingSystem == "TSL"){
                var driverImageClass = "fastestDriverImageTSL"
            } else {
                var driverImageClass = "fastestDriverImage"
            }

        } else {
            var fastestLap = undefined
            var competitorIndex = undefined
            var lastName = undefined
            var firstName = undefined
            var upperCaseLastName = undefined
            var width = "725px"
            var height = "90px"
        }
        var subText = ""
        if (this.props.Session.Competitors[competitorIndex]){
            if (this.props.Session.Competitors[competitorIndex].vehicle && this.props.Session.Competitors[competitorIndex].teamName){
                subText = this.props.Session.Competitors[competitorIndex].vehicle + " - " + this.props.Session.Competitors[competitorIndex].teamName
            } else if (this.props.Session.Competitors[competitorIndex].vehicle){
                subText = this.props.Session.Competitors[competitorIndex].vehicle
            } else if (this.props.Session.Competitors[competitorIndex].teamName){
                subText = this.props.Session.Competitors[competitorIndex].teamName
            } 
        }

        if (this.props.Session.SessionType != "Qual" && this.props.Session.SessionType != "Qualifying" && this.props.Session.SessionType != "Practice"){
            if (this.props.Session.Competitors[competitorIndex] && this.props.ClientStates.DriverPhotos == true) {
                //graphic with driver photos
                return ( 
                        <div className='fastestLapBox' style={{width: width, height:"150px", fontFamily: font}}>
                            <div className='fastestPink largeBox'>FL</div>
                            <div className='fastestNotificationBox' style={{height: height}}>
                                <div className='fastestLapTop'>
                                    <img className={nationFlagClass} src={"flags/"+nationFlag+".png"} onError={this.props.imageError}></img> 
                                    <div className='fastestLastNameText'>{upperCaseLastName}</div>
                                    <div className='fastestFirstNameText'>{firstName}</div>
                                </div>
                                <div className='fastestSubText'>{subText}</div>
                                <div className='fastestLapBottomPhoto'>
                                    <div className='fastestLapText'>{fastestLap}</div> 
                                </div>
                            <img className={driverImageClass} src={this.props.Session.Competitors[competitorIndex].Picture+".png"} onError={this.props.imageError}></img>    
                              
                            </div>
                            <div className={sponsorClass} style={{height: height}}>
                                <img className="FLSponsorImage" style={{marginTop: sponsorMargTop}} src={sponsorImage} onError={this.props.imageError}></img>
                            </div>
                        </div>
                );
            } else if (!this.props.Session.Competitors[competitorIndex]) {
                //graphics without driver photos
                return(
                <div className='fastestLapBox' style={{width: "0px", height:"150px", fontFamily: font}}>
                        <div className='fastestPink largeBox'>FL</div>
                        <div className='fastestNotificationBox' style={{height: "60px", fontFamily: font}}>
                            <div className='fastestLapTop'>
                                <div className='fastestLastNameText'></div>
                                <div className='fastestFirstNameText'></div>
                            </div>
                            <div className='fastestLapBottom'>
                                <div className='fastestLapText'></div> 
                            </div>
                        </div>
                        <div className={sponsorClass} style={{height: "60px"}}>
                            <img className="FLSponsorImage" style={{marginTop: sponsorMargTop}} src={sponsorImage}></img>
                        </div>
                    </div>  
                )
                
            } else {
                return ( 
                    <div className='fastestLapBox' style={{width: width, height:"150px", fontFamily: font}}>
                        <div className='fastestPink largeBox'>FL</div>
                        <div className='fastestNotificationBox' style={{height: "60px", fontFamily: font}}>
                            <div className='fastestLapTop'>
                                <div className='fastestLastNameText'>{upperCaseLastName}</div>
                                <div className='fastestFirstNameText'>{firstName}</div>
                            </div>
                            <div className='fastestLapBottom'>
                                <div className='fastestLapText'>{fastestLap}</div> 
                            </div>
                        </div>
                        <div className={sponsorClass} style={{height: "60px"}}>
                            <img className="FLSponsorImage" style={{marginTop: sponsorMargTop}} src={sponsorImage}></img>
                        </div>
                    </div>
            );
            } 
            } else {
                //If session is not a race - does not show
                return(
                    <div className='fastestLapBox' style={{width: "0px", height:"150px", fontFamily: font}}>
                            <div className='fastestPink largeBox'>FL</div>
                            <div className='fastestNotificationBox' style={{height: "60px", fontFamily: font}}>
                                <div className='fastestLapTop'>
                                    <div className='fastestLastNameText'></div>
                                    <div className='fastestFirstNameText'></div>
                                </div>
                                <div className='fastestLapBottom'>
                                    <div className='fastestLapText'></div> 
                                </div>
                            </div>
                            <div className={sponsorClass} style={{height: "60px"}}>
                                <img className="FLSponsorImage" style={{marginTop: sponsorMargTop}} src={sponsorImage}></img>
                            </div>
                        </div>  
                    )
            }
        } 
        
        
    // }
}




 
export default FastestLap;