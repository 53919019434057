import React, { PureComponent } from 'react';
import './stylesheets/RaceControl.css';

class RaceControl extends PureComponent {
    state = { 

     }

    render() {            
        //Graphic Info Calculations START
        if (this.props.ClientStates.TimingSystem == "TSL" || this.props.ClientStates.TimingSystem == "TimeService"){
            if (this.props.Notifications.length > 0){
                var messageObj = [this.props.Notifications[this.props.NotificationSelected]]
                var TSLMessage = messageObj[0].nt
                // Is there a driver number mentioned
                // console.log(messageObj)
                var indexNo = TSLMessage.indexOf("NO.")
                var indexCar = TSLMessage.indexOf("CAR")
                var indexCars = TSLMessage.indexOf("CARS")
                var indexAnd = TSLMessage.indexOf("AND")
                var indexHash = TSLMessage.indexOf("#")
                console.log(indexNo, indexCar, indexCars)
                if (indexNo > -1 || indexCar > -1 && indexCars == -1 || indexHash > -1 && indexAnd == -1){
                    var competitorNum = TSLMessage.substring(3,7)
                    if(indexHash >-1){
                        competitorNum = TSLMessage.substring(1,4)
                    }
                    console.log("compNum",parseInt(competitorNum))
                    var compIndex = this.props.Session.Competitors.findIndex( x => x.CompetitorNumber == parseInt(competitorNum))
                    if (compIndex > -1){
                        var message = "#"+competitorNum + this.props.Session.Competitors[compIndex].CompetitorName
                        var hyphenIndex = TSLMessage.indexOf("-")
                        if(hyphenIndex > -1){
                            var message2 = TSLMessage.substring(hyphenIndex+1)
                        } else {
                            var message2 = TSLMessage
                        }
                    } else {
                        var message = TSLMessage
                        var message2 = ""
                    }
                } else if (indexCars > -1){
                    var messageArray = TSLMessage.split('-')
                    var message = messageArray.shift()
                    var message2 = messageArray
                } else {
                    var messageObj = [this.props.Notifications[this.props.NotificationSelected]]
                    var message = TSLMessage
                    var message2 = ""
                }
                
            } else {
                var messageObj = [{initial: false}]
                var message = ""
                var message2 = ""
            }
        } else if (this.props.ClientStates.TimingSystem == "TimeService"){
            
        } else {
            if (this.props.Notifications.length > 0){
            var messageObj = this.props.Notifications[this.props.NotificationSelected]        
            //Smart Naming Calculations START
            if(messageObj){
                if(messageObj[0].cna){
                    if (messageObj[0].cna.length > 35){
                    var competitorName = messageObj[0].cna
                    var run = 0
                        while (competitorName.length > 35){
                            var nameArray = competitorName.split(",").join(" ").split(" ")
                            
                            if (run > 0){
                                var nameArray = competitorName.split("-").join(" ").split(" ")
                                var nameStart = nameArray[0]
                                var firstName = nameStart.substring(0,2*run+1)
                            } else {
                                var firstName = nameArray[0][0]
                            }

                            nameArray.shift()
                            var competitorName = firstName+"."+nameArray
                            run++

                            competitorName = competitorName.replace(/,/g, ' ');

                            if (run > 5){ break }
                        }
                    } else {
                        var competitorName = messageObj[0].cna
                    }
                } else {
                    var competitorName = "undefined"
                }
                //Smart Naming Calculations END
                var message = messageObj[0].nt.toUpperCase() + " " + messageObj[0].t.toUpperCase()
                var message2 = " #" + messageObj[0].cnu + " " + competitorName.toUpperCase()

                if (message.length > 40){
                    message = messageObj[0].nt.toUpperCase()
                }
            }
            } else {
                var message = ""
            }
        }
        
        //Graphic Info Calculations END

        //Display Calculation START
            if (this.props.Controls.NotificationShow == false || messageObj[0].initial == true){
                var notificationShow = "raceControlBoxOff"
                var width = "0px"
                if(this.props.ClientStates.TimingSystem == "TSL"){
                    var width2 = "780px"
                } else {
                    var width2 = "500px"
                }
            } else {
                var notificationShow = "raceControlBox"        
                //Width Calculations START
                if(message || message2){
                    if(this.props.ClientStates.TimingSystem == "TSL"){
                        if(message2.length > 40){
                            var width = "780px"
                            var width2 = "780px"
                        } else {
                            var width = "580px"
                            var width2 = "580px"
                        }
                        
                    } else if (message.length < 23 && message2.length < 23){
                        var width = "420px"
                        var width2 = "300px"
                    } else if (message.length > 70 || message2.length > 70){
                        var width = "620px"
                        var width2 = "500px"
                    } else if (message.length > message2.length){
                        var calc = 420+((message.length-23)*4)
                        var width = calc+"px"
                        var width2 = (calc-120)+"px"
                    } else {
                        var calc = 420+((message2.length-23)*4)
                        var width = calc+"px"
                        var width2 = (calc-120)+"px"
                    }
                } else {
                    var width = "0"
                    var width2 = "0"
                }
                //Width Calculations END
            }
        //Display Calculation END

        var font = {
            
        }

        if(this.props.ClientStates.Skin == "GTWS"){
            font = {
                fontFamily: "gtws-alt",
                fontSize: this.props.ClientStates.FontSize
            }
        } else if (this.props.ClientStates.Skin && this.props.ClientStates.FontSize){
            font = {
                fontFamily: this.props.ClientStates.Skin,
                fontSize: this.props.ClientStates.FontSize
            }
        } else if (this.props.ClientStates.Skin ) {
            font = {
                fontFamily: this.props.ClientStates.Skin,
                fontSize: "17px"
            }
        }
        
            return ( 
                    <div className={notificationShow} style={{width: width}}>
                        <div className='notificationBox' style={{width: width2}}>
                            <div className='notificationMessageTop' style={{fontFamily: font.fontFamily, fontSize: font.fontSize}}>
                                    {message}
                            </div>
                            <div className='notificationMessageBottom' style={{fontFamily: font.fontFamily, fontSize: font.fontSize}}>
                                    {message2}
                            </div>
                        </div>
                        <div className='StreamBlue rectangleBox' >
                            <div className='rectangleBoxText' style={{fontFamily: font.fontFamily, fontSize: font.fontSize}}>RACE CONTROL</div>
                        </div>
                        
                    </div>
            );
        } 
        
    // }
}

export default RaceControl;