import React, { PureComponent } from 'react';
import './driver.css';

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let vehicle1 = params.vehicle1;
  let vehicle2 = params.vehicle2;


class DataDisplay extends PureComponent {
    render() {
        const { label, value, unit } = this.props;
        return (
        <div className="data-display">
            <div className="label">{label}</div>
			
            <div className="value">{value}<span className="unit">{unit}</span></div>
        </div>
        );
    }
}

class DataDisplay2 extends PureComponent {
    render() {
        const { label, value, unit } = this.props;
        return (
        <div className="data-display">
            <div className="value">{value}<span className="unit">{unit}</span></div>
        </div>
        );
    }
}
  

class driverPage extends PureComponent {
    render() {
        var driverIndex = -1
        if(this.props.Session){
            if(this.props.Session.Competitors){
                var driverIndex =  this.props.Session.Competitors.findIndex( x => x.CompetitorNumber == vehicle1)
                var driverIndex2 = -1
                if(vehicle2){
                    var driverIndex2 =  this.props.Session.Competitors.findIndex( x => x.CompetitorNumber == vehicle2)
                }
                
            }
            if(this.props.Session.State){
                if (this.props.Session.fcy == true){
                    var stateColor = "elipse CautionYellow"
                    var sessionState = "SAFETY CAR"
                } else if (this.props.Session.State == "Running"){
                    var stateColor = "elipse PositiveGreen"
                    var sessionState = "GREEN FLAG"
                } else if (this.props.Session.State == "RedFlag"){
                    var stateColor = "elipse NegativeRed"
                    var sessionState = "RED FLAG"
                } else if (this.props.Session.State == "ChequeredFlag"){
                    var stateColor = "elipse Checkered"
                    var sessionState = "CHEQUERED"
                } else if (this.props.Session.State == "Ended" || this.props.Session.State == "Complete"){
                    var stateColor = "elipse Checkered"
                    var sessionState = "FINISHED"
                }
            } else {
                var stateColor = "elipse transparent" 
                var sessionState = "FORMATION"
            }

            if(this.props.Notifications){
                if(this.props.Notifications.length>2){
                    var Notifications = <div className='raceControl'><div className='rcMessage'>{this.props.Notifications[this.props.Notifications.length-1].t}  - {this.props.Notifications[this.props.Notifications.length-1].nt}</div><div className='rcMessage'>{this.props.Notifications[this.props.Notifications.length-2].t} - {this.props.Notifications[this.props.Notifications.length-2].nt}</div><div className='rcMessage'>{this.props.Notifications[this.props.Notifications.length-3].t} - {this.props.Notifications[this.props.Notifications.length-3].nt}</div></div>
                } else if(this.props.Notifications.length>1){
                    var Notifications = <div className='raceControl'><div className='rcMessage'>{this.props.Notifications[this.props.Notifications.length-1].t}  - {this.props.Notifications[this.props.Notifications.length-1].nt}</div><div className='rcMessage'>{this.props.Notifications[this.props.Notifications.length-2].t}  - {this.props.Notifications[this.props.Notifications.length-2].nt}</div></div>
                } else if(this.props.Notifications.length>0){
                    var Notifications = <div className='raceControl'><div className='rcMessage'>{this.props.Notifications[this.props.Notifications.length-1].t} - {this.props.Notifications[this.props.Notifications.length-1].nt}</div></div>
                } else {
                    var Notifications = <div className='raceControl'><div></div></div>
                }
            }
        }
        
        if(driverIndex2 == -1){
            if(driverIndex == -1){
                return(
                    <div className="racing-dashboard">
                        <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap" rel="stylesheet"></link>
                        <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet"></link>
                        <div className="headDiv">
                            <div className="remainingTimeDiv">
                                <div className='timeBG'>
                                    <div className='timeText'>REMAINING - 00:00:00</div>
                                </div>
                                <div className='timeBGAngle'></div>
                            </div>
                            <div className="dividingBar"></div>
                        </div>
                        <div>
                            <div className="vehicleNo">1</div>
                            <div className="vehicleNo2">6</div>
                        </div>
                        <table className="dataDisplay">
                            <tr>
                                <td className="rowTitle">POS</td>
                                <td className="dataCell">N/A</td>
                                <td className="dataCell">N/A</td>
                            </tr>
                            <tr>
                                <td className="rowTitle">GAP TO LEAD</td>
                                <td className="dataCell">TEST</td>
                                <td className="dataCell">TEST</td>
                            </tr>
                            <tr>
                                <td className="rowTitle">GAP INFRONT</td>
                                <td className="dataCell">TEST</td>
                                <td className="dataCell">TEST</td>
                            </tr>
                            <tr>
                                <td className="rowTitle">GAP BEHIND</td>
                                <td className="dataCell">TEST</td>
                                <td className="dataCell">TEST</td>
                            </tr>
                            <tr>
                                <td className="rowTitle">PIT STOPS</td>
                                <td className="dataCell">0</td>
                                <td className="dataCell">0</td>
                            </tr>
                        </table>
                        <div className="bottomDivider"></div>
                        <div className='sessionInfo'>
                            {Notifications}
                            <div className='sessionStateTitle'></div>
                            <div className='sessionState'>
                                <div className='elipse PositiveGreen'></div>
                                <div className='stateText'>NO SESSION</div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                var lapNo = 0
                if(this.props.Session.Competitors[driverIndex].LapData){
                    if(this.props.Session.Competitors[driverIndex].LapData[this.props.Session.Competitors[driverIndex].LapData.length-1]){
                        var lapNo = this.props.Session.Competitors[driverIndex].LapData[this.props.Session.Competitors[driverIndex].LapData.length-1].LapNo
                    }
                }
                var driverIndexBehind =  this.props.Session.Competitors.findIndex( x => x.Position == parseInt(this.props.Session.Competitors[driverIndex].Position)+1)
                var behindGap = ""
                
                if(driverIndexBehind != -1 ){
                    var behindGap = this.props.Session.Competitors[driverIndexBehind].Gap
                } 

                if(this.props.Session.Competitors[driverIndex].InPit == true){
                    var pits = "IN PIT"
                } else {
                    var pits = this.props.Session.Competitors[driverIndex].ps || "0"
                }

                var behind = ""
                var infront = ""
                if(this.props.Session.Competitors[driverIndex].Position == 1){
                    behind = ""
                    infront = ""
                } else {
                    behind = this.props.Session.Competitors[driverIndex].Behind || ""
                    infront = this.props.Session.Competitors[driverIndex].Gap || ""
                }

                return (
                    <div className="racing-dashboard">
                    <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap" rel="stylesheet"></link>
                    <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet"></link>
                    <div className="headDiv">
                        <div className="remainingTimeDiv">
                            <div className='timeBG'>
                                <div className='timeText'>REMAINING - {this.props.timeRemaining}</div>
                            </div>
                            <div className='timeBGAngle'></div>
                        </div>
                        <div className="dividingBar"></div>
                    </div>
                    <div>
                        <div className="vehicleNo">{vehicle1}</div>
                    </div>
                    <table className="dataDisplay">
                        <tr>
                            <td className="rowTitle">POS</td>
                            <td className="dataCell">{this.props.Session.Competitors[driverIndex].Position}</td>
                        </tr>
                        <tr>
                            <td className="rowTitle">GAP TO LEAD</td>
                            <td className="dataCell">{behind}</td>
                        </tr>
                        <tr>
                            <td className="rowTitle">GAP INFRONT</td>
                            <td className="dataCell">{infront}</td>
                        </tr>
                        <tr>
                            <td className="rowTitle">GAP BEHIND</td>
                            <td className="dataCell">{behindGap}</td>
                        </tr>
                        <tr>
                            <td className="rowTitle">PIT STOPS</td>
                            <td className="dataCell">{pits}</td>
                        </tr>
                    </table>
                    <div className="bottomDivider"></div>
                    <div className='sessionInfo'>
                        {Notifications}
                        <div className='sessionStateTitle'></div>
                        <div className='sessionState'>
                            <div className={stateColor}></div>
                            <div className='stateText'>{sessionState}</div>
                        </div>
                    </div>
                </div>
                );
            }
        } else if (driverIndex != -1) {
            var driverIndexBehind =  this.props.Session.Competitors.findIndex( x => x.Position == parseInt(this.props.Session.Competitors[driverIndex].Position)+1)
            var driverIndex2Behind =  this.props.Session.Competitors.findIndex( x => x.Position == parseInt(this.props.Session.Competitors[driverIndex2].Position)+1)
            var behindGap = ""
            var behindGap2 = ""
            
            if(driverIndexBehind != -1 ){
                //var behindGap = parseInt(this.props.Session.Competitors[driverIndex].Position)+1
                var behindGap = this.props.Session.Competitors[driverIndexBehind].Gap
            } 

            if(driverIndex2Behind != -1 ){
                //var behindGap2 = parseInt(this.props.Session.Competitors[driverIndex2].Position)+1
                var behindGap2 = this.props.Session.Competitors[driverIndex2Behind].Gap
            } 

            if(this.props.Session.Competitors[driverIndex].InPit == true){
                var pits1 = "IN PIT"
            } else {
                var pits1 = this.props.Session.Competitors[driverIndex].ps || "0"
            }

            if(this.props.Session.Competitors[driverIndex2].InPit == true){
                var pits2 = "IN PIT"
            } else {
                var pits2 = this.props.Session.Competitors[driverIndex2].ps || "0"
            }

            var behind1 = ""
            var infront1 = ""
            if(this.props.Session.Competitors[driverIndex].Position == 1){
                behind1 = ""
                infront1 = ""
            } else {
                behind1 = this.props.Session.Competitors[driverIndex].Behind || ""
                infront1 = this.props.Session.Competitors[driverIndex].Gap || ""
            }
            var behind2 = ""
            var infront2 = ""
            if(this.props.Session.Competitors[driverIndex2].Position == 1){
                behind2 = ""
                infront2 = ""
            } else {
                behind2 = this.props.Session.Competitors[driverIndex2].Behind || ""
                infront2 = this.props.Session.Competitors[driverIndex2].Gap || ""
            }

            return(

                <div className="racing-dashboard">
                <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap" rel="stylesheet"></link>
                <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet"></link>
                <div className="headDiv">
                    <div className="remainingTimeDiv">
                        <div className='timeBG'>
                            <div className='timeText'>REMAINING - {this.props.timeRemaining}</div>
                        </div>
                        <div className='timeBGAngle'></div>
                    </div>
                    <div className="dividingBar"></div>
                </div>
                <div>
                    <div className="vehicleNo">{vehicle1}</div>
                    <div className="vehicleNo2">{vehicle2}</div>
                </div>
                <table className="dataDisplay">
                    <tr>
                        <td className="rowTitle">POS</td>
                        <td className="dataCell">{this.props.Session.Competitors[driverIndex].Position}</td>
                        <td className="dataCell">{this.props.Session.Competitors[driverIndex2].Position}</td>
                    </tr>
                    <tr>
                        <td className="rowTitle">GAP TO LEAD</td>
                        <td className="dataCell">{behind1}</td>
                        <td className="dataCell">{behind2}</td>
                    </tr>
                    <tr>
                        <td className="rowTitle">GAP INFRONT</td>
                        <td className="dataCell">{infront1}</td>
                        <td className="dataCell">{infront2}</td>
                    </tr>
                    <tr>
                        <td className="rowTitle">GAP BEHIND</td>
                        <td className="dataCell">{behindGap}</td>
                        <td className="dataCell">{behindGap2}</td>
                    </tr>
                    <tr>
                        <td className="rowTitle">PIT STOPS</td>
                        <td className="dataCell">{pits1}</td>
                        <td className="dataCell">{pits2}</td>
                    </tr>
                </table>
                <div className="bottomDivider"></div>
                <div className='sessionInfo'>
                        {Notifications}
                    <div className='sessionStateTitle'></div>
                    <div className='sessionState'>
                        <div className={stateColor}></div>
                        <div className='stateText'>{sessionState}</div>
                    </div>
                </div>
            </div>
            )
        } else {
            return(
                <div>waiting on data</div>
            )
        }
        
  

    }
  }
export default driverPage ;
