import React, { PureComponent } from 'react';

class wsGridTile extends PureComponent {

    render() { 

        var rowStyle = {
            position: 'absolute',
            marginTop: '-25px',
            fontFamily: 'gtws-bold',
            fontSize: '20px',
            color: '#fff'
        }

        if (this.props.position % 2 === 0) {
            var side = '1050px';
            var offset = 100
            var rowHTML = <div style={rowStyle}></div>
            var delay = '150ms'
        } else {
            var side = '140px';
            var offset = 170
            var rowHTML = <div id="rowNum" style={rowStyle}>ROW {(this.props.position+1)/2}</div>
            var delay = '0ms'
        }

        //Calculates the margin shift for the current page to be visible
        var boost = ((this.props.Controls.GridPage-1)*-214)+offset
        var opacity = 1

        //Calculates if the tile should be below the current page (if highest number on page is lower than the tile position = boost the margin to ensure outside)
        if (parseInt(this.props.Controls.GridPage)*2 < parseInt(this.props.position)){
            boost = boost + 200
        }
        
        //Calculates if the tile should be above the current page (if lowest number on page is higher than the tile position = reduce the margin to ensure outside)
        if((parseInt(this.props.Controls.GridPage)-1)*2 >= parseInt(this.props.position)){
            boost = boost - 200
            opacity = 0
        }
        
        var rowHeight = 80
        var rowWidth = 750

        var tileRow = {
            position: 'absolute',
            width: rowWidth + "px",
            height: rowHeight + 'px',
            top: ((this.props.position-1)*107)+boost + "px",
            marginLeft: side,
            backgroundColor: "transparent",
            opacity: opacity,
            transitionDelay: delay
        }

        var leftPos ={
            float: 'left',
            height: '160%',
            aspectRatio: '1',
            color: '#fff',
            backgroundColor: '#000',
            fontFamily: 'gtws-bold',
            fontSize: '80px',
            textAlign: 'center',
            lineHeight: rowHeight*1.6 + 'px'
        }

        var leftNum ={
            float: 'left',
            height: '70%',
            aspectRatio: '1/0.78',
            color: '#fff',
            backgroundColor: this.props.driver.nbc || "black",
            color: this.props.driver.nfc || "white",
            fontFamily: 'gtws-bold',
            fontSize: '40px',
            textAlign: 'center',
            lineHeight: rowHeight*0.75 + 'px'
        }

        var logo = {
            float: 'left',
            backgroundColor: '#000',
            height: '90%',
            aspectRatio: '1',
        }

        var leftName ={
            float: 'left',
            height: '90%',
            width: (rowWidth-(rowHeight*2)-50)+'px',
            color: '#fff',
            paddingLeft: '10px',
            backgroundColor: "rgb(0 0 0 / 95%)",
            fontFamily: 'gtws',
            fontSize: '38px',
            textAlign: 'left',
            lineHeight: rowHeight*0.9 + 'px'
        }

        var driverImg = {
            height: "225px",
            position: "absolute"
        }

        var driverContainer = {
            position: "absolute",
            marginTop: "-60px",
            marginLeft: "513px",
            overflow: "hidden",
            height: "140px",
            width: "200px"
        }

        var flag = {
            position: "absolute",
            width: "45px",
            height: "27px",
            marginTop: "63px",
            marginLeft: "154px",
        }

        var leftFade ={
            float: 'left',
            height: '70%',
            width: (rowWidth-(rowHeight*2)-50)+'px',
            color: '#fff',
            paddingLeft: '10px',
            backgroundColor: "rgb(0 0 0 / 45%)",
            fontFamily: 'gtws',
            fontSize: '40px',
            textAlign: 'left',
            lineHeight: '60px',
            // background: 'linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 70%)'
        }

            return ( 
                <div id="tileRow"  className="AnimateLTGrid" style={tileRow}>
                    {rowHTML}
                    <div id="LeftPos" style={leftPos}>{this.props.position}</div>
                    <img id="logo" style={logo} src={"https://alphalive.co.uk/TimingGraphics/gtws/logos/"+this.props.driver.vehicle+".png"} onError={this.props.imageError}></img>
                    <div id="LeftName" style={leftName}>{this.props.driver.CompetitorName.toUpperCase()}</div>
                    <div id="LeftNum" style={leftNum}>{this.props.driver.CompetitorNumber}</div>
                    <div id="LeftFade" style={leftFade}>{this.props.driver.qualification}</div>
                    <div style={driverContainer}>
                        <img style={flag} src={"./flags/"+this.props.driver.nat+".png"} onError={this.props.imageError}></img>
                        <img style={driverImg} src={this.props.driver.Picture+"-3.png"} onError={this.props.imageError}></img>
                    </div>
                </div>
            );
        }
            
}
export default wsGridTile ;
